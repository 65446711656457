<template>
  <common-form
    name="second-ins"
    @submit="submitData"
  >
    <pp-frame>
      <template #message>
        <h2 class="blue-grey--text">
          <p>{{ $t('Secondary Insurance') }}</p>
        </h2>

        <div class="secondary--text">
          {{ translate(`
          Do you have additional insurance in addition to primary insurance
          coverage?
          `) }}<br>
          {{ translate(`
          If so, please accurately complete all fields on this page to
          help us maximize your potential insurance coverage.
          `) }}<br>
          {{ translate(`
          Refer to your medical insurance card for this information.
          `) }}
        </div>
      </template>

      <v-row class="flex-grow-0">
        <v-col
          class="d-flex align-enter"
          cols="12"
        >
          <h3 class="d-inline blue-grey--text toggle__tittle">
            {{ $t('Do you have secondary medical insurance?') }}
          </h3>

          <common-toggle
            v-model="info.hasSMIns"
            class="ml-3"
          />
        </v-col>

        <v-subheader>
          {{ translate(`
          Please complete the information below for the PRIMARY INSURED PARTY
          listed on your SECONDARY insurance card.
          `) }}
        </v-subheader>
        <v-col cols="12">
          <v-select
            v-model="info.sMRelation"
            :items="relationItems"
            :label="`1. ${sMRelationLabel}:`"
            :rules="!info.hasSMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="info.sMPartyfname"
            :label="`2. ${sMPartyfnameLabel}:`"
            :rules="!info.hasSMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="info.sMPartymname"
            :label="`3. ${sMPartymnameLabel}:`"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-text-field
            v-model="info.sMPartylname"
            :label="`4. ${sMPartylnameLabel}:`"
            :rules="!info.hasSMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <vuetify-date-picker
            v-model="info.ins2Dob"
            :label="`5a. ${insDobLabel}:`"
            :rules="!info.hasSMIns ? [] : [
              $validators.isValid(),
              $validators.isValidDate()
            ]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-select
            v-model="info.sMGender"
            :items="genderItems"
            :label="`5b. ${sMGenderLabel}`"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="insContact.company"
            :label="`6a. ${companyLabel}`"
            :rules="!info.hasSMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="insContact.address1"
            :label="`6b. ${address1Label}`"
            :rules="!info.hasSMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="insContact.address2"
            :label="`6c. ${address2Label}`"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="insContact.city"
            :label="`6d. ${cityLabel}`"
            :rules="!info.hasSMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="insContact.state"
            :label="`6e. ${stateLabel}`"
            :items="states"
            :rules="!info.hasSMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="insContact.zip"
            v-mask="'#####-####'"
            :label="`6f. ${zipLabel}`"
            autocomplete="off"
            :disabled="!info.hasSMIns"
            :rules="!info.hasSMIns ? [] : [
              $validators.isValid(),
              $validators.isZipCode()
            ]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="insContact.phone"
            v-mask="'(###) ###-####'"
            :label="`6g. ${phoneLabel}`"
            autocomplete="off"
            :rules="!info.hasSMIns ? [] : [
              $validators.isValid(),
              $validators.isValidPhone()
            ]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="insContact.fax"
            v-mask="'(###) ###-####'"
            :label="`6h. ${faxLabel}`"
            autocomplete="off"
            :rules="[!insContact.fax ||
              $validators.isValidFax()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="insContact.email"
            :label="`6i. ${emailLabel}`"
            :rules="!info.hasSMIns ? [] : [
              !insContact.email ||
                $validators.isEmail()
            ]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.sMInsId"
            :label="`7. ${sMInsIdLabel}`"
            :rules="!info.hasSMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.sMInsGrp"
            :label="`8. ${sMInsGrpLabel}`"
            :rules="!info.hasSMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.sMInsPlan"
            :label="`9. ${sMInsPlanLabel}`"
            :rules="!info.hasSMIns ? [] :
              [$validators.isValid()]"
            :disabled="!info.hasSMIns"
            dense
          />
        </v-col>
      </v-row>

      <template #actions>
        <v-row class="ma-0">
          <v-btn to="/register/insurance">
            <v-icon>mdi-chevron-double-left</v-icon>
            {{ $t('Back') }}
          </v-btn>

          <v-spacer />

          <v-btn
            color="primary"
            type="submit"
            :loading="updatingProfile"
          >
            {{ $t('Proceed') }}
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </pp-frame>
  </common-form>
</template>

<script>
import { ref, watch, onMounted, computed } from '@vue/composition-api'
import moment from 'moment'

import { utils, validators, events } from 'ui-common'
import { useProfile } from '@/use/profile'
import { states, Page2ndInsurance } from '@/constants'
import { useQuestionnaire } from '@/use/questionnaire'

const useSecondIns = () => {
  const info = ref({
    hasSMIns: false,
    sMRelation: '',
    sMPartyfname: '',
    sMPartymname: '',
    sMPartylname: '',
    ins2Dob: null,
    sMInsId: '',
    sMInsGrp: '',
    sMInsPlan: ''
  })

  const insContact = ref({
    company: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    fax: '',
    email: ''
  })

  const {
    secondaryProfile,

    secondaryInsuranceContacts,

    updatingProfile,
    updateSecondaryProfile
  } = useProfile()

  const {
    getCompletedSections
  } = useQuestionnaire()

  const feedData = () => {
    const {
      hasSMIns,
      sMRelation = '',
      sMPartyfname = '',
      sMPartymname = '',
      sMPartylname = '',
      ins2Dob,
      sMGender = '',
      sMInsId = '',
      sMInsGrp = '',
      sMInsPlan = ''
    } = secondaryProfile.value

    const {
      company,
      address1,
      address2,
      city,
      state,
      zip,
      phone,
      fax,
      email
    } = secondaryInsuranceContacts.value

    info.value = {
      hasSMIns: hasSMIns === 'Yes',
      sMRelation,
      sMPartyfname,
      sMPartymname,
      sMPartylname,
      ins2Dob: ins2Dob && moment(ins2Dob).format('MM/DD/YYYY'),
      sMGender,
      sMInsId,
      sMInsGrp,
      sMInsPlan
    }

    insContact.value = {
      company,
      address1,
      address2,
      city,
      state,
      zip,
      phone: validators.formatPhoneNumber(phone),
      fax: validators.formatPhoneNumber(fax),
      email
    }
  }

  const submitData = async () => {
    try {
      const { hasSMIns } = info.value

      if (!hasSMIns) {
        await updateSecondaryProfile({
          info: {
            hasSMIns: 'No',
            lastRegSect: Page2ndInsurance
          }
        })
      } else {
        const { ins2Dob } = info.value

        const {
          company = '',
          address1 = '',
          address2 = '',
          city = '',
          state = '',
          zip = '',
          phone = '',
          fax = '',
          email = ''
        } = insContact.value

        const ins2DobField = 'ins2_dob'
        await updateSecondaryProfile({
          info: {
            ...info.value,
            hasSMIns: 'Yes',
            [ins2DobField]: ins2Dob && moment(ins2Dob).format('MM/DD/YYYY'),
            lastRegSect: Page2ndInsurance
          },
          insContact: {
            type: 2,
            company,
            address1,
            address2,
            city,
            state,
            zip,
            phone: validators.formatPhoneNumber(phone, false),
            fax: validators.formatPhoneNumber(fax, false),
            email
          }
        })
      }
      await getCompletedSections()
      utils.navigate('/register')
    } catch (err) { console.log(err) }
  }

  onMounted(() => {
    watch(() => info.sMRelation, (value) => {
      if (value !== 'Self') { return }

      const {
        firstname,
        middlename,
        lastname,
        dob,
        gender,

        add1,
        add2,
        city,
        state,
        zip,
        email
      } = secondaryProfile.value

      info.value.sMPartyfname = firstname
      info.value.sMPartymname = middlename
      info.value.sMPartylname = lastname
      info.value.ins2Dob = dob
      info.value.sMGender = gender

      info.value.address1 = add1
      info.value.address2 = add2
      info.value.city = city
      info.value.state = state
      info.value.zip = zip
      info.value.email = email
    })
  })

  return {
    info,
    insContact,
    feedData,
    submitData,
    updatingProfile
  }
}

export default {
  setup: (props, context) => {
    const { root } = context

    const relationItems = [
      {
        text: 'Self',
        value: 'Self'
      },
      {
        text: 'Spouse',
        value: 'Spouse'
      },
      {
        text: 'Child',
        value: 'Child'
      },
      {
        text: 'Other',
        value: 'Other'
      }
    ]
    const genderItems = [
      {
        text: 'Male',
        value: 'Male'
      },
      {
        text: 'Female',
        value: 'Female'
      }
    ]

    const {
      info,
      insContact,
      feedData,
      submitData,
      updatingProfile
    } = useSecondIns()

    onMounted(() => {
      feedData()
      root.$nextTick(() => {
        events.emit('form.reset.second-ins')
      })
    })

    return {
      info,
      insContact,
      states,
      submitData,
      updatingProfile,

      relationItems: computed(() =>
        relationItems.map(item => {
          item.text = root.$i18n.t(item.value)
          return item
        })
      ),
      genderItems: computed(() =>
        genderItems.map(item => {
          item.text = root.$i18n.t(item.value)
          return item
        })
      ),

      sMRelationLabel: computed(() =>
        root.$i18n.t('Your relationship to primary insured')
      ),
      sMPartyfnameLabel: computed(() =>
        root.$i18n.t('Insured First Name')
      ),
      sMPartymnameLabel: computed(() =>
        root.$i18n.t('MI')
      ),
      sMPartylnameLabel: computed(() =>
        root.$i18n.t('Insured Last Name')
      ),
      insDobLabel: computed(() =>
        root.$i18n.t('Insured Date of Birth')
      ),
      sMGenderLabel: computed(() =>
        root.$i18n.t('Insured Gender')
      ),
      companyLabel: computed(() =>
        root.$i18n.t('Insurance Company')
      ),
      address1Label: computed(() =>
        root.$i18n.t('Address 1')
      ),
      address2Label: computed(() =>
        root.$i18n.t('Address 2')
      ),
      cityLabel: computed(() =>
        root.$i18n.t('City')
      ),
      stateLabel: computed(() =>
        root.$i18n.t('State')
      ),
      zipLabel: computed(() =>
        root.$i18n.t('Zip')
      ),
      phoneLabel: computed(() =>
        root.$i18n.t('Phone')
      ),
      faxLabel: computed(() =>
        root.$i18n.t('Fax')
      ),
      emailLabel: computed(() =>
        root.$i18n.t('Email')
      ),
      sMInsIdLabel: computed(() =>
        root.$i18n.t('Insurance ID')
      ),
      sMInsGrpLabel: computed(() =>
        root.$i18n.t('Group #')
      ),
      sMInsPlanLabel: computed(() =>
        root.$i18n.t('Plan Name')
      )
    }
  }
}
</script>
