var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('common-form',{attrs:{"name":"second-ins"},on:{"submit":_vm.submitData}},[_c('pp-frame',{scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('h2',{staticClass:"blue-grey--text"},[_c('p',[_vm._v(_vm._s(_vm.$t('Secondary Insurance')))])]),_c('div',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.translate(" Do you have additional insurance in addition to primary insurance coverage? "))),_c('br'),_vm._v(" "+_vm._s(_vm.translate(" If so, please accurately complete all fields on this page to help us maximize your potential insurance coverage. "))),_c('br'),_vm._v(" "+_vm._s(_vm.translate(" Refer to your medical insurance card for this information. "))+" ")])]},proxy:true},{key:"actions",fn:function(){return [_c('v-row',{staticClass:"ma-0"},[_c('v-btn',{attrs:{"to":"/register/insurance"}},[_c('v-icon',[_vm._v("mdi-chevron-double-left")]),_vm._v(" "+_vm._s(_vm.$t('Back'))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.updatingProfile}},[_vm._v(" "+_vm._s(_vm.$t('Proceed'))+" "),_c('v-icon',[_vm._v("mdi-chevron-double-right")])],1)],1)]},proxy:true}])},[_c('v-row',{staticClass:"flex-grow-0"},[_c('v-col',{staticClass:"d-flex align-enter",attrs:{"cols":"12"}},[_c('h3',{staticClass:"d-inline blue-grey--text toggle__tittle"},[_vm._v(" "+_vm._s(_vm.$t('Do you have secondary medical insurance?'))+" ")]),_c('common-toggle',{staticClass:"ml-3",model:{value:(_vm.info.hasSMIns),callback:function ($$v) {_vm.$set(_vm.info, "hasSMIns", $$v)},expression:"info.hasSMIns"}})],1),_c('v-subheader',[_vm._v(" "+_vm._s(_vm.translate(" Please complete the information below for the PRIMARY INSURED PARTY listed on your SECONDARY insurance card. "))+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.relationItems,"label":("1. " + _vm.sMRelationLabel + ":"),"rules":!_vm.info.hasSMIns ? [] :
            [_vm.$validators.isValid()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.info.sMRelation),callback:function ($$v) {_vm.$set(_vm.info, "sMRelation", $$v)},expression:"info.sMRelation"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":("2. " + _vm.sMPartyfnameLabel + ":"),"rules":!_vm.info.hasSMIns ? [] :
            [_vm.$validators.isValid()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.info.sMPartyfname),callback:function ($$v) {_vm.$set(_vm.info, "sMPartyfname", $$v)},expression:"info.sMPartyfname"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":("3. " + _vm.sMPartymnameLabel + ":"),"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.info.sMPartymname),callback:function ($$v) {_vm.$set(_vm.info, "sMPartymname", $$v)},expression:"info.sMPartymname"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":("4. " + _vm.sMPartylnameLabel + ":"),"rules":!_vm.info.hasSMIns ? [] :
            [_vm.$validators.isValid()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.info.sMPartylname),callback:function ($$v) {_vm.$set(_vm.info, "sMPartylname", $$v)},expression:"info.sMPartylname"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('vuetify-date-picker',{attrs:{"label":("5a. " + _vm.insDobLabel + ":"),"rules":!_vm.info.hasSMIns ? [] : [
            _vm.$validators.isValid(),
            _vm.$validators.isValidDate()
          ],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.info.ins2Dob),callback:function ($$v) {_vm.$set(_vm.info, "ins2Dob", $$v)},expression:"info.ins2Dob"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.genderItems,"label":("5b. " + _vm.sMGenderLabel),"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.info.sMGender),callback:function ($$v) {_vm.$set(_vm.info, "sMGender", $$v)},expression:"info.sMGender"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":("6a. " + _vm.companyLabel),"rules":!_vm.info.hasSMIns ? [] :
            [_vm.$validators.isValid()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.insContact.company),callback:function ($$v) {_vm.$set(_vm.insContact, "company", $$v)},expression:"insContact.company"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":("6b. " + _vm.address1Label),"rules":!_vm.info.hasSMIns ? [] :
            [_vm.$validators.isValid()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.insContact.address1),callback:function ($$v) {_vm.$set(_vm.insContact, "address1", $$v)},expression:"insContact.address1"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":("6c. " + _vm.address2Label),"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.insContact.address2),callback:function ($$v) {_vm.$set(_vm.insContact, "address2", $$v)},expression:"insContact.address2"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":("6d. " + _vm.cityLabel),"rules":!_vm.info.hasSMIns ? [] :
            [_vm.$validators.isValid()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.insContact.city),callback:function ($$v) {_vm.$set(_vm.insContact, "city", $$v)},expression:"insContact.city"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"label":("6e. " + _vm.stateLabel),"items":_vm.states,"rules":!_vm.info.hasSMIns ? [] :
            [_vm.$validators.isValid()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.insContact.state),callback:function ($$v) {_vm.$set(_vm.insContact, "state", $$v)},expression:"insContact.state"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#####-####'),expression:"'#####-####'"}],attrs:{"label":("6f. " + _vm.zipLabel),"autocomplete":"off","disabled":!_vm.info.hasSMIns,"rules":!_vm.info.hasSMIns ? [] : [
            _vm.$validators.isValid(),
            _vm.$validators.isZipCode()
          ],"dense":""},model:{value:(_vm.insContact.zip),callback:function ($$v) {_vm.$set(_vm.insContact, "zip", $$v)},expression:"insContact.zip"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],attrs:{"label":("6g. " + _vm.phoneLabel),"autocomplete":"off","rules":!_vm.info.hasSMIns ? [] : [
            _vm.$validators.isValid(),
            _vm.$validators.isValidPhone()
          ],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.insContact.phone),callback:function ($$v) {_vm.$set(_vm.insContact, "phone", $$v)},expression:"insContact.phone"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],attrs:{"label":("6h. " + _vm.faxLabel),"autocomplete":"off","rules":[!_vm.insContact.fax ||
            _vm.$validators.isValidFax()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.insContact.fax),callback:function ($$v) {_vm.$set(_vm.insContact, "fax", $$v)},expression:"insContact.fax"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":("6i. " + _vm.emailLabel),"rules":!_vm.info.hasSMIns ? [] : [
            !_vm.insContact.email ||
              _vm.$validators.isEmail()
          ],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.insContact.email),callback:function ($$v) {_vm.$set(_vm.insContact, "email", $$v)},expression:"insContact.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":("7. " + _vm.sMInsIdLabel),"rules":!_vm.info.hasSMIns ? [] :
            [_vm.$validators.isValid()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.info.sMInsId),callback:function ($$v) {_vm.$set(_vm.info, "sMInsId", $$v)},expression:"info.sMInsId"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":("8. " + _vm.sMInsGrpLabel),"rules":!_vm.info.hasSMIns ? [] :
            [_vm.$validators.isValid()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.info.sMInsGrp),callback:function ($$v) {_vm.$set(_vm.info, "sMInsGrp", $$v)},expression:"info.sMInsGrp"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":("9. " + _vm.sMInsPlanLabel),"rules":!_vm.info.hasSMIns ? [] :
            [_vm.$validators.isValid()],"disabled":!_vm.info.hasSMIns,"dense":""},model:{value:(_vm.info.sMInsPlan),callback:function ($$v) {_vm.$set(_vm.info, "sMInsPlan", $$v)},expression:"info.sMInsPlan"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }